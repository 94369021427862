<template>
  <div>
    <div class="mx-auto flex flex-col h-screen w-screen">
      <Navigation />
      <MultiView />
      <Switcher />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import MultiView from '@/components/MultiView.vue'
import Switcher from '@/components/Switcher.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    MultiView,
    Switcher
  },
  data() {
    return {
    }
  },
  mounted() {

    const videoRef = document.getElementById("Output");
    const canvasRef = document.getElementById("Preview");
    const audioRef = document.getElementById("Audio");
    
    let localStream;

    let getLocalStream = () => {
      navigator.mediaDevices.getUserMedia({audio: true, video: true}).then((stream) => {
          console.log('Stream found');
          localStream = stream;

          var audioTracks = stream.getAudioTracks();
          var audioStream = new MediaStream();
          audioStream.addTrack(audioTracks[0]);
          audioRef.srcObject = audioStream;
          this.$socket.connect();
          requestAnimationFrame(updateCanvas);
          start_stream();
        })
    }
    getLocalStream();

    const updateCanvas = () => {
      
      const ctx = canvasRef.getContext('2d');

      ctx.drawImage(
        videoRef,
        0,
        0,
        videoRef.clientWidth,
        videoRef.clientHeight
      );

      ctx.fillStyle = '#FB3C4E';
      ctx.font = '50px Akkurat';
      ctx.fillText("Austin's Test Live Stream", 8, 50, canvasRef.width - 20);

      requestAnimationFrame(updateCanvas);
    };

    console.log(localStream);


    var socket = this.$socket;

    function start_stream() {
      const videoOutputStream = canvasRef.captureStream();
      const audioOutputStream = audioRef.captureStream();
      var mediaRecorderRef;
      
      const outputStream = new MediaStream();
      [audioOutputStream, videoOutputStream].forEach(function (s) {
        s.getTracks().forEach(function (t) {
          outputStream.addTrack(t);
          videoRef.srcObject = outputStream;
        });
      });

      console.log(MediaRecorder.isTypeSupported("video/webm;codecs=h264"));

      mediaRecorderRef = new MediaRecorder(outputStream, {
        mimeType: 'video/webm;codecs=h264',
        videoBitsPerSecond: 3000000,
      });

      mediaRecorderRef.addEventListener('dataavailable', (e) => {
        socket.emit('data', e.data);
      });

      // mediaRecorderRef.addEventListener('stop', () => {
      //   stopStreaming();
      //   wsRef.current.close();
      // });

      mediaRecorderRef.start(3000);
      
    }




  }
}







</script>

<style src="./index.css"/>
