<template>
      <div id="preview-container" class="flex flex-1 overflow-hidden justify-center items-center">
        <canvas id="Preview" class="flex-1 min-w-0 border"></canvas>

        <video id="Output" class="hidden"></video>
        <audio id="Audio"></audio>
      </div>
</template>


<script>

module.exports = {
  name: 'MultiViewPreview',
  mounted() {
    var audioDevices = this.$deviceManager.getVideoDevices();
    console.log(audioDevices);

    // var devices = this.$deviceManager.deviceList();
    // devices.foreach(function(device) {
    //   console.log(device);
    // })
  }
}
</script>

<style scoped>
  #Output {
    width: 1920px;
    height: 1080px;
  }
  #Preview {
    width: 1920px;
    height: 1080px;
  }
</style>

