import { createApp } from 'vue'
import { createStore } from 'vuex'
import Application from './App.vue'
import socketio from 'socket.io-client'
import VueSocketIO from 'vue-3-socket.io'
// import MediaStream from './plugins/media-stream'
import DeviceManager from '@/plugins/DeviceManager'

let app = createApp(Application)

const store = createStore({
    state () {
        return {
            count: 1
        }
    }
})
app.use(store)

const optionsVueIO = {
    debug: true,
    connection: socketio('https://stream.gablmedia.com', { autoConnect: false }),
    vuex: {
        store,
        actionPrefix: 'SOCKET_IO_',
        mutationPrefix: 'SOCKET_IO_'
    },
    auth: {
        'test': 'test'
    },
    sockets: {
        connect: function() {
            console.log("Socket connected")
        },
        ready: function () {
            console.log("ready")
        }
    }
}
app.use(new VueSocketIO(optionsVueIO))


const optionsDeviceManager = {
    vuex: {
        store,
        actionPrefix: 'DEVICE_MANAGER_',
        mutationPrefix: 'DEVICE_MANAGER_'
    },
}
app.use(DeviceManager, optionsDeviceManager)

app.mount('#app')