<template>
  <div id="Switcher" style="height: 300px;">
    <div class="w-full bg-gray-100 dark:bg-gray-700 dark:text-gray-200">
      <div class="">
        Scenes
      </div>
    </div>
    <button id="AddDevice">Add Source Device</button>
  </div>
</template>
