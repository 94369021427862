<template>
    <div id="MultiView" class="flex flex-1 flex-row">
      <MultiViewPreview/>
      <div class="w-5"></div>
      <MultiViewProgram/>
    </div>
</template>


<script>
import MultiViewPreview from '@/components/MultiViewPreview.vue'
import MultiViewProgram from '@/components/MultiViewProgram.vue'

export default {
  name: 'MultiView',
  components: {
    MultiViewPreview,
    MultiViewProgram
  },
  data() {
    return {
      test: null,
    }
  },
  methods: {
    clickedOnClient() {
      this.$socket.emit('message', {
        from: this.$socket.id,
      })
    },
  },
  mounted() {
    this.$socket.on('user-connected', socketId => {
      console.log("User connected");
      this.test = socketId;
    });
    this.$socket.on('connected', socketId => {
      console.log("Connected");
      this.test = socketId;
    });
  },
}

</script>