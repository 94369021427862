
class Device {
    constructor(device) {
        this.device = device;
    }
}


class DeviceManager {

    constructor(options) {
        this.deviceConstraints = {'video': true, 'audio': true};
        this.devices = [];
        this.framerate = options['framerate'];
        this.mediaStream;

        navigator.mediaDevices.getUserMedia(this.deviceConstraints)
        .then(this.processStream.bind(this))
        .then(this.getDeviceList.bind(this))
        // .catch(userMediaError);
    }

    processStream(stream) {
        this.mediaStream = stream;
    }

    getDeviceList() {
        navigator.mediaDevices.enumerateDevices(this.deviceConstraints)
        .then(this.processDevices.bind(this))
        // .catch(deviceError);
    }

    processDevices(devices) {
        for (var i=0; i<devices.length; i++) {
            this.devices.push(new Device(devices[i]));
        }
    }

    getVideoDevices() {
        var deviceList = [];
        this.devices.forEach(function(device) {
            if (device.device) {
                deviceList.push(device);
            }
        })
        return deviceList;
    }

}

const defaultOptions = {
    framerate: 30
};

export default {
    install: (Vue, options) => {
        Vue.DEVICE_MANAGER_VERSION = 'v1.0'

        let userOptions = {...defaultOptions, ...options};
        Vue.config.globalProperties.$deviceManager = new DeviceManager(userOptions)

    }
}



